import makeAxiosRequest, { GetAxiosResponse } from './axios.instance';

export interface RequestList {
  auth: {
    signIn: GetAxiosResponse;
    signUp: GetAxiosResponse;
    getProfile: GetAxiosResponse;
    getPermissions: GetAxiosResponse;
    updateProfile: GetAxiosResponse;
    confirmEmail: GetAxiosResponse;
    resetPassword: GetAxiosResponse;
    changePassword: GetAxiosResponse;
    confirmResetPassword: GetAxiosResponse;
  };
  spotify: {
    getSpotifyArtist: GetAxiosResponse;
    getTopTracks: GetAxiosResponse;
    getAllTracks: GetAxiosResponse;
    searchTracks: GetAxiosResponse;
  };
  deals: {
    getDealList: GetAxiosResponse;
    createDeal: GetAxiosResponse;
    dealSubmit: GetAxiosResponse;
    getDeal: GetAxiosResponse;
    updateDeal: GetAxiosResponse;
    calculateAdvance: GetAxiosResponse;
    uploadReport: GetAxiosResponse;
    deleteDeal: GetAxiosResponse;
  };
  reports: {
    createInitialReport: GetAxiosResponse;
    finaliseReport: GetAxiosResponse;
    createInitialReportLocalStorage: GetAxiosResponse;
    remove: GetAxiosResponse;
    validateLocalReceipts: GetAxiosResponse;
    submitLocalReceipts: GetAxiosResponse;
    removeFromLocalStorage: GetAxiosResponse;
    statusUploaded: GetAxiosResponse;
    getDownloadUrl: GetAxiosResponse;
  };
  analytics: {
    getEngagement: GetAxiosResponse;
    getStreamsByPlatform: GetAxiosResponse;
    getStreamsByTrack: GetAxiosResponse;
    getTopCountries: GetAxiosResponse;
    getAllPlatforms: GetAxiosResponse;
    getArtistProfiles: GetAxiosResponse;
    getAdminAnalytics: GetAxiosResponse;
    socialPlatformEngagements: GetAxiosResponse;
    socialPlatformAudiences: GetAxiosResponse;
  };
  invitation: {
    validate: GetAxiosResponse;
    createInvitation: GetAxiosResponse;
    getInvitedArtists: GetAxiosResponse;
  };
  recoupments: {
    renewals: GetAxiosResponse;
    statusUpdate: GetAxiosResponse;
  };
  preference: {
    account: GetAxiosResponse;
    updateWeeklyAnalyticsEmail: GetAxiosResponse;
  };
  referral: {
    createReferral: GetAxiosResponse;
  };
}

const api = {
  auth: {
    signIn: makeAxiosRequest('/auth/login', 'post'),
    signUp: makeAxiosRequest('/users', 'post'),
    getProfile: makeAxiosRequest('auth/profile', 'get'),
    getPermissions: makeAxiosRequest('auth/permissions', 'get'),
    updateProfile: makeAxiosRequest('/users', 'patch'),
    confirmEmail: makeAxiosRequest('/users/confirm-email', 'post'),
    resetPassword: makeAxiosRequest('/users/reset-password', 'post'),
    changePassword: makeAxiosRequest('/users/change-password', 'post'),
    confirmResetPassword: makeAxiosRequest('/users/confirm-reset-password', 'post'),
  },
  spotify: {
    getSpotifyArtist: makeAxiosRequest(
      (query) => `/spotify/get-artist/${encodeURIComponent(`${query}`)}`,
      'get'
    ),
    getTopTracks: makeAxiosRequest((id) => `/spotify/get-top-tracks/${id}`, 'get'),
    getAllTracks: makeAxiosRequest((id) => `/spotify/get-all-tracks/${id}`, 'get'),
    searchTracks: makeAxiosRequest('/spotify/search-tracks', 'get'),
  },
  deals: {
    getDealList: makeAxiosRequest('/deals/list', 'get'),
    createDeal: makeAxiosRequest('/deals', 'post'),
    dealSubmit: makeAxiosRequest((id) => `/deals/${id}/submit`, 'post'),
    getDeal: makeAxiosRequest((id) => `/deals/${id}`, 'get'),
    updateDeal: makeAxiosRequest((id) => `/deals/${id}`, 'put'),
    calculateAdvance: makeAxiosRequest((id) => `/deals/${id}/advance`, 'get'),
    uploadReport: makeAxiosRequest((id) => `/deals/${id}/file`, 'post'),
    deleteDeal: makeAxiosRequest((id) => `/deals/${id}`, 'delete'),
  },
  reports: {
    createInitialReport: makeAxiosRequest((id) => `/reports/deal/${id}`, 'post'),
    finaliseReport: makeAxiosRequest('/reports', 'post'),
    createInitialReportLocalStorage: makeAxiosRequest(
      (id) => `/reports/receipt-parsing/deal/${id}`,
      'post'
    ),
    remove: makeAxiosRequest((id) => `/reports/${id}`, 'delete'),
    validateLocalReceipts: makeAxiosRequest((id) => `/reports/validate-receipts/deal/${id}`, 'get'),
    submitLocalReceipts: makeAxiosRequest((id) => `/reports/submit-review/deal/${id}`, 'post'),
    removeFromLocalStorage: makeAxiosRequest((id) => `/reports/localStorage/${id}`, 'delete'),
    statusUploaded: makeAxiosRequest((id) => `/reports/${id}/status-uploaded`, 'post'),
    getDownloadUrl: makeAxiosRequest((id) => `/reports/${id}/get-download-url`, 'get'),
  },
  analytics: {
    getEngagement: makeAxiosRequest(() => `/analytics/engagements`, 'get'),
    getStreamsByPlatform: makeAxiosRequest(() => `/analytics/streams/platforms`, 'get'),
    getStreamsByTrack: makeAxiosRequest(() => `/analytics/streams/tracks`, 'get'),
    getTopCountries: makeAxiosRequest(() => `/analytics/countries`, 'get'),
    getAllPlatforms: makeAxiosRequest(() => `/analytics/platforms`, 'get'),
    getArtistProfiles: makeAxiosRequest(() => `/analytics/profiles`, 'get'),
    getAdminAnalytics: makeAxiosRequest((id) => `/analytics/admin-reports/${id}`, 'get'),
    socialPlatformEngagements: makeAxiosRequest(
      () => `/analytics/social-platforms/engagements`,
      'get'
    ),
    socialPlatformAudiences: makeAxiosRequest(() => `/analytics/social-platforms/audiences`, 'get'),
  },
  invitation: {
    validate: makeAxiosRequest(() => `/invitation/validate`, 'post'),
    createInvitation: makeAxiosRequest(() => `/invitation`, 'post'),
    getInvitedArtists: makeAxiosRequest(() => `/invitation`, 'get'),
  },
  recoupments: {
    renewals: makeAxiosRequest('/recoupments/renewals', 'get'),
    statusUpdate: makeAxiosRequest((id) => `/recoupments/${id}/renewals/status`, 'put'),
  },
  preference: {
    account: makeAxiosRequest('/preference/account', 'get'),
    updateWeeklyAnalyticsEmail: makeAxiosRequest(
      '/preference/account/update-weekly-analytics-email',
      'put'
    ),
  },
  referral: {
    createReferral: makeAxiosRequest(() => `/referral`, 'post'),
  },
} as RequestList;

export default api;
