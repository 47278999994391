import { IData } from 'hooks/useDealState';
import { ITrack } from 'hooks/useUserData';
import { ISpotifyData } from './apiTypes';
import { IOption } from '../components/inputs/autocomplete-input';

export const formatNumberToAbbreviation = (n: number) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
};

export const formatValueToDollars = (value: number | string) => {
  const numberValue = typeof value === 'number' ? value : Number(value);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  return formatter.format(numberValue);
};

export const formatToNiceNumber = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  return formatter.format(value);
};

export const getKeyValue = <U extends keyof T, T extends object>(key: U, obj: T) => obj[key];

export interface IPayloadTrack {
  trackSpotifyId: string;
  name: string;
  dateReleased: string;
  popularity?: number;
  ownership?: number;
  total_artists?: number;
  isCustom?: boolean;
}

export const createTracksPayload = (
  tracks: IData[],
  checkboxes: Record<string, boolean>,
  sliders: Record<string, number>
) => {
  const result: IPayloadTrack[] = [];

  Object.keys(checkboxes).forEach((trackSpotifyId) => {
    if (checkboxes[trackSpotifyId]) {
      const currentTrack = tracks.filter(({ id }) => id === trackSpotifyId)[0];

      result.push({
        trackSpotifyId,
        name: currentTrack.name,
        dateReleased: currentTrack.date_released,
      });
    }
  });

  return result;
};

export const formatTracksToFormFormat = (allTracks: IData[], checkedTracks: ITrack[]) => {
  const completedTracks: IData[] = [...allTracks];

  const allTracksIds = allTracks.map(({ id }) => id);

  checkedTracks.forEach(({ trackSpotifyId, name, dateReleased }) => {
    if (!allTracksIds.includes(trackSpotifyId)) {
      const formattedCheckedTracks = {
        id: trackSpotifyId,
        name,
        date_released: dateReleased,
      };
      completedTracks.push(formattedCheckedTracks);
    }
  });

  const initialSliders = allTracks.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [`${item.id}`]: 0,
      }),
    {}
  );

  const checkedSliders = checkedTracks.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [`${item.trackSpotifyId}`]: 0,
      }),
    {}
  );

  const slider: Record<string, number> = Object.assign(initialSliders, checkedSliders);

  const initialCheckboxes = allTracks.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [`${item.id}`]: false,
      }),
    {}
  );

  const checkedCheckboxes = checkedTracks.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [`${item.trackSpotifyId}`]: true,
      }),
    {}
  );

  const checkbox: Record<string, boolean> = Object.assign(initialCheckboxes, checkedCheckboxes);

  return { completedTracks, slider, checkbox };
};

export const getExtensionFromFileName = (fileName: string) => {
  return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);
};

export const getIsAllowedExtensions = (fileName: string, allowedExtensions: string[]) => {
  const fileExtension = getExtensionFromFileName(fileName);
  return allowedExtensions.includes(fileExtension);
};

export async function retry<T>(fn: () => Promise<T>, retries = 3): Promise<T> {
  try {
    return await fn();
  } catch (err) {
    if (retries === 1) {
      throw err;
    }
    return await retry(fn, retries - 1);
  }
}
export interface IArtistOption extends IOption {
  images: {
    width: number;
    height: number;
    url: string;
  }[];
}

export function spotifyArtistsToOptions(data: ISpotifyData): IArtistOption[] {
  return data.items && Array.isArray(data.items)
    ? data.items.map(({ id, name, images }) => ({
        label: name,
        value: id,
        images,
        imageUrl: images.slice(-1)[0]?.url,
      }))
    : [
        {
          label: data.name,
          value: data.id,
          images: data.images,
          imageUrl: data.images?.slice(-1)[0]?.url,
        },
      ];
}

export function getFormattedDate(dateOrString: string | Date | null) {
  if (!dateOrString) {
    return null;
  }
  const date = typeof dateOrString === 'string' ? new Date(dateOrString) : dateOrString;
  const formattedDate =
    date.getFullYear() +
    '/' +
    String(date.getMonth() + 1).padStart(2, '0') +
    '/' +
    String(date.getDate()).padStart(2, '0');
  return formattedDate;
}

export function numberToShorthand(value: string | number): string {
  const num = typeof value === 'string' ? parseFloat(value) : value;

  if (isNaN(num)) {
    throw new Error('Invalid number');
  }

  const absNum = Math.abs(num);

  let result: string;

  if (absNum >= 1e12) {
    result = (num / 1e12).toFixed(1).replace(/\.0$/, '') + 'T';
  } else if (absNum >= 1e9) {
    result = (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B';
  } else if (absNum >= 1e6) {
    result = (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (absNum >= 1e3) {
    result = (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'K';
  } else {
    result = num.toString();
  }

  return result;
}
